import { render, staticRenderFns } from "./transfer-user.vue?vue&type=template&id=6da7d174&scoped=true&"
import script from "./transfer-user.ts?vue&type=script&lang=ts&"
export * from "./transfer-user.ts?vue&type=script&lang=ts&"
import style0 from "./transfer-user.vue?vue&type=style&index=0&id=6da7d174&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da7d174",
  null
  
)

export default component.exports